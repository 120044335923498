'use client';

import { EventMap, WrappedEvent } from '@drift-labs/sdk';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Observable, Subject, merge, ObservableInput } from 'rxjs';
import { buffer, filter } from 'rxjs/operators';
import { DriftBlockchainEventSubjectContext } from '../../providers/driftEvents/driftEventSubjectProvider';

/**
 * release: () => Triggers the buffer to emit the next group of data
 * releaseAndGet: () => Release and return the data in the buffer
 */
export type DriftEventBuffer<T = WrappedEvent<keyof EventMap>> = {
	buffer: Observable<T[]>;
	release: () => void;
	releaseAndGet: () => Promise<T[]>;
};

function useDriftEventBuffer(
	eventFilter: (event: WrappedEvent<keyof EventMap>) => boolean
): DriftEventBuffer {
	const eventSubjectContext = useContext(DriftBlockchainEventSubjectContext);

	const allEventsSubject = useMemo(() => {
		return eventSubjectContext.userEventObservable &&
			eventSubjectContext.globalFillEventObservable
			? merge(
					...([
						eventSubjectContext.userEventObservable,
						eventSubjectContext.globalFillEventObservable,
					] as ObservableInput<WrappedEvent<keyof EventMap>>[])
			  )
			: undefined;
	}, [eventSubjectContext]);

	const [returnValue, setReturnValue] = useState<DriftEventBuffer>(undefined);

	useEffect(() => {
		if (!allEventsSubject) {
			return undefined;
		}

		const bufferTrigger = new Subject<void>();

		let currentBufferedEvents: WrappedEvent<keyof EventMap>[] = [];

		const newBuffer = allEventsSubject.pipe(
			filter(eventFilter),
			buffer(bufferTrigger)
		);

		// Subscription to hold buffered events for the triggerAndReturn utility methood
		const subscription = newBuffer.subscribe((events) => {
			currentBufferedEvents = events;
		});

		const trigger = () => bufferTrigger.next();

		setReturnValue({
			buffer: newBuffer,
			release: trigger,
			releaseAndGet: () => {
				return new Promise<WrappedEvent<keyof EventMap>[]>((res) => {
					trigger();
					setTimeout(() => res(currentBufferedEvents), 0); // Resolve on the next tick
				});
			},
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [allEventsSubject, eventFilter]);

	return returnValue;
}

export default useDriftEventBuffer;
