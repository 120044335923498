'use client';

import { EventMap, OrderAction, WrappedEvent, ZERO } from '@drift-labs/sdk';
import { useCallback, useRef } from 'react';
import { ENUM_UTILS, MarketId } from '@drift/common';
import useDriftEventBuffer, { DriftEventBuffer } from './useDriftEventBuffer';
import useDriftStore from '../../stores/DriftStore/useDriftStore';

const INCREASING_SLOTS_ONLY = true;

export const fillEventFilter = (
	driftEvent: WrappedEvent<keyof EventMap>,
	opts?: {
		marketId?: MarketId;
	}
) => {
	if (driftEvent.eventType !== 'OrderActionRecord') {
		return false;
	}

	const typedEvent = driftEvent as WrappedEvent<'OrderActionRecord'>;

	if (opts?.marketId) {
		if (
			!opts.marketId.equals(
				new MarketId(typedEvent.marketIndex, typedEvent.marketType)
			)
		) {
			return false;
		}
	}

	if (!ENUM_UTILS.match(typedEvent.action, OrderAction.FILL)) {
		return false;
	}

	if (typedEvent.baseAssetAmountFilled.eq(ZERO)) {
		return false;
	}

	return true;
};

const useFillEventsBuffer = (opts?: {
	currentMarketOnly?: boolean;
	customFilter?: (event: WrappedEvent<'OrderActionRecord'>) => boolean;
}) => {
	const lastSeenSlot = useRef(0);

	const currentMarketId = useDriftStore((s) => s.selectedMarket.marketId);

	const lastSeenSlotFilter = (driftEvent: WrappedEvent<keyof EventMap>) => {
		if (!INCREASING_SLOTS_ONLY) {
			return true;
		}

		if (lastSeenSlot.current > driftEvent.slot) {
			return false;
		}

		return true;
	};

	const filteringFunc = useCallback(
		(driftEvent: WrappedEvent<keyof EventMap>) => {
			// Base Filter
			const baseFilterResult = opts?.currentMarketOnly
				? fillEventFilter(driftEvent, { marketId: currentMarketId })
				: fillEventFilter(driftEvent);

			if (!baseFilterResult) {
				return false;
			}

			// Slot Filter
			const slotFilterResult = lastSeenSlotFilter(driftEvent);

			if (!slotFilterResult) {
				return false;
			}

			// Custom Filter
			const typedEvent = driftEvent as WrappedEvent<'OrderActionRecord'>;

			const customFilterResult = opts?.customFilter
				? opts.customFilter(typedEvent)
				: true;

			if (!customFilterResult) {
				return false;
			}

			return true;
		},
		[currentMarketId, opts?.currentMarketOnly]
	);

	const eventBuffer = useDriftEventBuffer(filteringFunc) as DriftEventBuffer<
		WrappedEvent<'OrderActionRecord'>
	>;

	return eventBuffer;
};

export default useFillEventsBuffer;
