import { Subject, Observable } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { UniqueCircularBuffer } from './UniqueCircularBuffer';

export function dedupeSubject<T>(
	inputSubject: Subject<T>,
	windowSize: number,
	uniquenessKeyGenerator: (a: T) => string
): Observable<T> {
	const buffer = new UniqueCircularBuffer<T>(
		windowSize,
		uniquenessKeyGenerator
	);

	const dedupedObservable = inputSubject.pipe(
		filter((value) => {
			const added = buffer.add(value);

			return added;
		}),
		share()
	);

	return dedupedObservable;
}
