'use client';

import { CandleResolution } from '@drift-labs/sdk';
import { CANDLE_UTILS, MarketId, ONE_DAY_MS } from '@drift/common';
import dayjs from 'dayjs';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { dlog } from '../../dev';
import useFillEventsBuffer from '../../hooks/driftEvents/useFillEventsBuffer';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useMarketStateStore from '../../stores/useMarketStateStore';
import ExchangeHistoryClient from '../../utils/exchangeHistoryClient';
import { notify } from '../../utils/notifications';
import { UICandleClient } from './UICandleClient';
import { ONE_MINUTE_MS, ONE_HOUR_MS } from 'src/constants/math';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';

// the key is derived from the local storage key used by TradingView - chart_last_selected_interval
type CandlesResolutionFromLocalStorage =
	| '1'
	| '3'
	| '5'
	| '15'
	| '30'
	| '60'
	| '240'
	| '360'
	| '480'
	| '1D'
	| '3D'
	| '1W'
	| '1M';

// this config map is based on TradingView's network requests params

type ResolutionFetchDetailsMap = {
	[K in CandlesResolutionFromLocalStorage]: {
		resolution: CandleResolution;
		countback: number;
	};
};

const STANDARD_COUNTBACK = 340; // usually its 320, but we add a buffer of 20 candles; otherwise the chart will make a second call if the countback is less than required

const RESOLUTION_FETCH_DETAILS_MAP: ResolutionFetchDetailsMap = {
	'1': {
		resolution: '1',
		countback: STANDARD_COUNTBACK,
	},
	'3': { resolution: '1', countback: STANDARD_COUNTBACK * 3 },
	'5': {
		resolution: '5',
		countback: STANDARD_COUNTBACK,
	},
	'15': {
		resolution: '15',
		countback: STANDARD_COUNTBACK,
	},
	'30': {
		resolution: '15',
		countback: STANDARD_COUNTBACK * 2,
	},
	'60': {
		resolution: '60',
		countback: STANDARD_COUNTBACK,
	},
	'240': {
		resolution: '240',
		countback: STANDARD_COUNTBACK,
	},
	'360': {
		resolution: '60',
		countback: STANDARD_COUNTBACK * 6,
	},
	'480': {
		resolution: '240',
		countback: STANDARD_COUNTBACK * 2,
	},
	'1D': {
		resolution: 'D',
		countback: STANDARD_COUNTBACK,
	},
	'3D': {
		resolution: 'D',
		countback: STANDARD_COUNTBACK * 3,
	},
	'1W': {
		resolution: 'D',
		countback: STANDARD_COUNTBACK * 7,
	},
	'1M': {
		resolution: 'D',
		countback: STANDARD_COUNTBACK * 31,
	},
};

export const CandlesContext = React.createContext<UICandleClient>(undefined);

const candleHistoryFetcher = async (
	resolution: CandleResolution,
	marketId: MarketId,
	to: number, // ms
	from?: number, // ms
	countBack?: number,
	allowedRetryAttempts = 1
) => {
	try {
		const getHistory = () =>
			ExchangeHistoryClient.getMarketHistory(
				resolution,
				marketId.marketIndex,
				marketId.marketType,
				to,
				from,
				countBack
			);

		let historicalData = await getHistory();

		let hadToRetry = false;

		// Retry on timeouts or internal errors
		if (!historicalData.success && [500, 504].includes(historicalData.status)) {
			hadToRetry = true;

			let retryCount = 0;

			while (retryCount < allowedRetryAttempts) {
				dlog(
					`candle_robustness`,
					`candle_fetch_retry_attempt :: ${retryCount}`
				);

				historicalData = await getHistory();

				if (historicalData.success) {
					break;
				}

				retryCount++;
			}
		}

		if (!historicalData.success) {
			if (historicalData.status === 429) {
				notify({
					id: 'candles_too_many_requests',
					type: 'warning',
					message: 'Too many requests',
				});
			}

			console.error(
				`Failed to get historical bars in tv chart data feed: ${historicalData.message}`
			);

			return {
				candles: [],
				lastTradeSeen: undefined,
			};
		}

		if (historicalData.success && hadToRetry) {
			dlog(`candle_robustness`, `fetched_good_candles_after_retry_attempts`);
		}

		return historicalData?.body;
	} catch (e) {
		console.error(e);
		throw e;
	}
};

/**
 * This utility function is used to calculate the fromTs and toTs for the initial fetch of candles.
 * It closely mimics the logic used by TradingView.
 */
const getToAndFromTs = (
	candlesResolutionFromLocalStorage: CandlesResolutionFromLocalStorage
) => {
	const { resolution, countback } =
		RESOLUTION_FETCH_DETAILS_MAP[candlesResolutionFromLocalStorage];

	const currentExactDate = dayjs();
	let toTsDayjs: dayjs.Dayjs;

	if (resolution === 'D') {
		toTsDayjs = currentExactDate
			.utc()
			.add(1, 'day')
			.set('hour', 0)
			.set('minute', 0);
	} else {
		toTsDayjs = currentExactDate.add(1, 'minute');
	}

	const toTs = toTsDayjs.set('millisecond', 0).valueOf();

	let fromTs = 0;

	if (resolution === '1') {
		fromTs = toTs - countback * ONE_MINUTE_MS;
	} else if (resolution === '5') {
		fromTs = toTs - countback * 5 * ONE_MINUTE_MS;
	} else if (resolution === '15') {
		fromTs = toTs - countback * 15 * ONE_MINUTE_MS;
	} else if (resolution === '60') {
		fromTs = toTs - countback * ONE_HOUR_MS;
	} else if (resolution === '240') {
		fromTs = toTs - countback * 4 * ONE_HOUR_MS;
	} else if (resolution === 'D') {
		fromTs = toTs - countback * ONE_DAY_MS;
	}

	return {
		fromTs,
		toTs,
	};
};

const CandlesProvider = (props: PropsWithChildren<any>) => {
	const get = useDriftStore((s) => s.get);
	const appEventEmitter = useAppEventEmitter();
	const getSelectedMarketId = () => get()?.selectedMarket?.marketId;
	const getMarketDataState = useMarketStateStore((s) => s.get);
	const getSelectedMarketOraclePrice = () =>
		getMarketDataState().marketDataState[getSelectedMarketId().key].oracle
			.price;
	const selectedMarketId = useDriftStore((s) => s.selectedMarket.marketId);

	const getCurrentMarketTradeHistory = () => get().marketTradeHistory;

	// Don't put a market filter in the buffer here, because we don't reinitialize the buffer when we change markets. Filtering needs to happen internally.
	const fillsBuffer = useFillEventsBuffer({
		customFilter: CANDLE_UTILS.filterOrderActionsForCandles,
	});

	const [candleClient, setCandleClient] = useState<UICandleClient>(undefined);

	const marketIdRef = useRef(selectedMarketId);

	useEffect(() => {
		marketIdRef.current = selectedMarketId;
	}, [selectedMarketId]);

	/**
	 * Optimistically fetch candles for the initially selected market and resolution.
	 * The benefit is that the candles are ready to be loaded when the TV chart is ready to display them,
	 * hence saving the round trip cost of fetching the candles when the TV chart requests them for the first time.
	 */
	useEffect(() => {
		const resolution =
			window.localStorage.getItem('chart_last_selected_interval') ?? '30'; // 30 minutes is the default used by TradingView

		const { resolution: candlesResolution, countback } =
			RESOLUTION_FETCH_DETAILS_MAP[resolution];
		const { fromTs, toTs } = getToAndFromTs(
			resolution as CandlesResolutionFromLocalStorage
		);

		candleHistoryFetcher(
			candlesResolution,
			selectedMarketId,
			toTs,
			fromTs,
			countback
		)
			.then((result) => {
				if (!selectedMarketId.equals(marketIdRef.current)) {
					// the market has changed, so we don't store the candles
					return;
				}

				UICandleClient.setFirstLoadedCandles({
					result,
					marketId: selectedMarketId,
					fetchedAtTs: Date.now(),
					from: fromTs,
					to: toTs,
				});
			})
			.finally(() => {
				UICandleClient.hasLoadedFirstLoadedCandlesResolver();
			});
	}, []);

	useEffect(() => {
		if (!fillsBuffer) {
			return undefined;
		}

		let newOrCurrentCandleClient = candleClient;

		if (!newOrCurrentCandleClient) {
			newOrCurrentCandleClient = new UICandleClient(
				getCurrentMarketTradeHistory,
				fillsBuffer,
				getSelectedMarketOraclePrice,
				candleHistoryFetcher,
				appEventEmitter
			);
			// console.log('** NEW CANDLE CLIENT **');
			setCandleClient(newOrCurrentCandleClient);
		} else {
			// console.log('** NEW FILLS BUFFER, SAME CANDLE CLIENT');
			newOrCurrentCandleClient.setUnhandledMarketTradesBuffer(fillsBuffer);
		}
	}, [fillsBuffer, candleClient]);

	// Handle clearing all subscriptions if the candleClient changes
	useEffect(() => {
		if (!candleClient) {
			return;
		}

		return () => {
			candleClient.clearAllSubscriptions();
		};
	}, [candleClient]);

	return <CandlesContext value={candleClient}>{props.children}</CandlesContext>;
};

export default CandlesProvider;
